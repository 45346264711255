<script setup>
const props = defineProps({
  icon: String,
  label: String,
  href: [String, Object],
  selected: Boolean,
  alt: String,
  small: Boolean,
  brandName: {
    type: String,
    default: () => ''
  }
});
const now = computed(() => Date.now())
const formattedBrand = computed(()=> {
  if(!props.brandName) return "";
  return props.brandName
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
})

</script>

<template>
  <div :class="['aspect-square sm:aspect-auto p-1 sm:p-0']">
    <NuxtLink :to="props.href"
              :title="'leasing samochodów ' + formattedBrand"
              :class="['flex flex-col font-extralight text-sm justify-center items-center w-20 h-20 justify-self-center rounded hover:shadow-brand-hover cursor-pointer select-none transition-all duration-300',{'bg-gray-100':selected},{'!w-14 !h-14':small}]">
      <div class="w-20 h-20 flex justify-center items-center"
      :class="[{'!w-16 !h-12': label},{'!w-14 !h-14':small}]">
        <img :src="icon" :alt="alt ? alt : `leasing ${formattedBrand}`" />
      </div>
      <div v-if="label" class="text-center">
        {{ label }}
      </div>
    </NuxtLink>
  </div>
</template>
